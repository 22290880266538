/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
export default {
  nextSlide: state => {
    state.currentSlide++
  },
  prevSlide: state => {
    state.currentSlide--
  },
  setSlide: (state, value) => {
    state.currentSlide = value
  }
}
