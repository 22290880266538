export default {
  startTimestamp: 0,
  endTimestamp: 0,
  score: 0,
  currentHighlightedTech: 0,
  techs: [
    {
      id: 0,
      img: 'img/techs/javascript.jpeg'
    },
    {
      id: 1,
      img: 'img/techs/es6.jpg'
    },
    {
      id: 2,
      img: 'img/techs/firebase.jpg'
    },
    {
      id: 3,
      img: 'img/techs/angular2.jpg'
    },
    {
      id: 4,
      img: 'img/techs/react.png'
    },
    {
      id: 5,
      img: 'img/techs/ionic.jpg'
    },
    {
      id: 6,
      img: 'img/techs/vue.png'
    },
    {
      id: 7,
      img: 'img/techs/ts.jpg'
    },
    {
      id: 8,
      img: 'img/techs/sass.png'
    },
    {
      id: 9,
      img: 'img/techs/mongo.png'
    },
    {
      id: 10,
      img: 'img/techs/node.png'
    },
    {
      id: 11,
      img: 'img/techs/kendo.png'
    },
    {
      id: 12,
      img: 'img/techs/nx.png'
    },
    {
      id: 13,
      img: 'img/techs/parse.png'
    }
  ]
}
