import Vue from 'vue'
import { isNil } from 'lodash'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import CheckLogin from '@/views/CheckLogin'

import store from '@/store'

Vue.use(Router)

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    // {
    //   path: '/products',
    //   name: 'products',
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-products" */ '@/views/Products.vue')
    // },
    // {
    //   path: '/products/:id',
    //   name: 'product',
    //   props: true,
    //   component: () =>
    //     import(/* webpackChunkName: "client-chunk-product-details" */ '@/views/Product.vue'),
    //     meta: {
    //       authNotRequired: true
    //     }
    // },
    {
      path: '/rxjs',
      name: 'rxjs',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-rxjs" */ '@/views/RxJSCourse.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/experiments',
      name: 'experiments',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-rxjs" */ '@/views/Experiments.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    { path: '*', redirect: '/home' }
  ]
})

router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null ? '/login' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
  return true
})

export default router
