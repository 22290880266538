var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('h2',[_vm._v("Projects")]),_c('div',{staticClass:"homepage-content"},[_c('card',{attrs:{"project":{
        link: 'https://blackpaper-b60f0.web.app/',
        color: '#e5002b',
        name: 'Firebase Market',
        desc: 'CMS for online shop developed with Angular 8 and Firebase',
        img: 'img/market.jpg'
      }}}),_c('card',{attrs:{"project":{
        link:
          'https://play.google.com/store/apps/details?id=poker.alexandru.minzatanu',
        color: '#e5002b',
        name: 'Poker Club',
        desc:
          'True random poker slots game developed with Ionic Framework and cordova',
        img: 'img/poker-club.png'
      }}}),_c('card',{attrs:{"project":{
        link: 'https://accountant-tools-10.web.app/reception-note',
        color: '#e5002b',
        name: 'Reception Notes',
        desc:
          'Angular 7 app that cand generate and calculate reception notes in printing format',
        img: 'img/r-note.jpg'
      }}}),_c('card',{attrs:{"project":{
        link: 'https://alexminzatanu.com',
        color: '#e5002b',
        name: 'Portfolio Site',
        desc: 'This site PWA with VueJs and firebase hosting and cloud store',
        img: 'img/me.jpg'
      }}})],1),_c('h2',[_vm._v("Social")]),_c('div',{staticClass:"homepage-content"},[_c('social')],1),_c('h2',[_vm._v("Stackshare")]),_c('p',[_vm._v("(You have 30s to click on highlighted icons)")]),_c('h3',[_vm._v(" Time: "),_c('strong',[_vm._v(_vm._s(_vm.endTimestamp - _vm.startTimestamp))]),_vm._v(" ms / Score: "),_c('strong',[_vm._v(_vm._s(_vm.score))])]),_c('div',{staticClass:"homepage-content"},[_c('section',{staticClass:"max-width"},_vm._l((_vm.techs),function(tech){return _c('div',{key:tech.id,staticClass:"tech",class:{ 'tech--hl': tech.id === _vm.currentHighlightedTech },style:({ backgroundImage: 'url(' + tech.img + ')' }),on:{"click":function($event){return _vm.clickedHighlightedTech(tech.id)}}})}),0)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',[_c('span',[_vm._v(" Alex Mînzățanu "),_c('br'),_vm._v("alexandru.minzatanu@gmail.com "),_c('br'),_vm._v("2020 ")])])}]

export { render, staticRenderFns }