/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
export default {
  clickedHighlightedTech: (state, value) => {
    if (state.score === 0) {
      state.startTimestamp = Date.now()
    }
    if (
      state.currentHighlightedTech === value &&
      state.endTimestamp - state.startTimestamp < 30000
    ) {
      state.score++
      state.currentHighlightedTech = Math.floor(
        Math.random() * state.techs.length
      )
      state.endTimestamp = Date.now()
    } else if (state.endTimestamp - state.startTimestamp > 30000) {
      const message = `You scored ${
        state.score
      } in 30 seconds! Do you want to play again?`
      const r = confirm(message)
      if (r === true) {
        state.score = 0
        state.endTimestamp = 0
        state.startTimestamp = 0
      }
    }
  }
}
