import state from './rxjscourse.state'
import mutations from './rxjscourse.mutations'
import actions from './rxjscourse.actions'
import getters from './rxjscourse.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
