<template>
  <header class="navbar" :class="{ offline: !networkOnLine }">
    <router-link to="/home">
      <span class="site-name title-desktop">{{ appTitle }}</span>
      <span class="site-name title-mobile">{{ appShortTitle }}</span>
    </router-link>
    <div class="links">
      <nav class="nav-links">
        <div class="nav-item" style="display:none">
          <router-link to="/experiments">
            <i class="fas fa-flask"></i>
            <span class="can-hide">Experiments</span>
          </router-link>
        </div>
        <div class="nav-item">
          <a
            href="https://alexandruminzatanu.github.io/angular-workshop/docs/doc1"
            target="_blank"
          >
            <i class="fab fa-angular"></i>
            <span class="can-hide">Angular Course</span>
          </a>
        </div>
        <div class="nav-item">
          <router-link to="/rxjs">
            <i class="fas fa-code"></i>
            <span class="can-hide">RXJS Course</span>
          </router-link>
        </div>
        <a-divider type="vertical" style="display:none"/>
        <div v-if="!isUserLoggedIn && networkOnLine" class="nav-item" style="display:none">
          <router-link to="/login">
            <i class="fas fa-sign-in-alt"></i> Login
          </router-link>
        </div>
        <div
          v-if="isUserLoggedIn && networkOnLine"
          class="nav-item logout-item"
          @click="logout"
        >
          <i class="fas fa-sign-out-alt"></i> Logout
        </div>
        <div v-if="!networkOnLine" class="nav-item offline-label">Offline</div>
      </nav>

      <img
        v-if="isUserLoggedIn && networkOnLine"
        class="user-picture can-hide"
        :src="user.photoURL"
        alt="Avatar"
      />
    </div>
  </header>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'
import { Divider } from 'ant-design-vue'

export default {
  components: {
    'a-divider': Divider
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  right: 0;
  height: $navbar-height;
  box-sizing: border-box;
  padding: 0.7rem 1.5rem;
  line-height: 2.2rem;
  box-shadow: $shadow;
  margin: 10px;

  background: linear-gradient(
    135deg,
    adjust-hue($navbar-bg, -20deg),
    $navbar-bg
  );
  border-radius: $border-radius;

  .nav-icon {
    width: 35px;
    height: auto;
  }
  a {
    display: flex;
    align-items: center;
  }

  .title-desktop {
    display: inline;
  }

  .title-mobile {
    display: none;
  }

  @media (max-width: 777px) {
    padding: 0.7rem 0.7rem;

    .can-hide {
      display: none;
    }

    .title-desktop {
      display: none;
    }

    .title-mobile {
      display: block;
    }
  }

  .site-name {
    font-size: 1.3rem;
    color: $text-color;
    position: relative;
    font-weight: 500;
  }

  .logo {
    height: 24px;
    padding-right: 8px;
  }

  .links {
    padding-left: 1.5rem;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 0.9rem;
    position: absolute;
    right: 1.5rem;
    top: 0.7rem;
    display: flex;

    .nav-links {
      display: flex;
      align-items: center;
      justify-content: center;

      .nav-item {
        position: relative;
        display: inline-block;
        margin-left: 1.5rem;
        line-height: 2.2rem;
        .fas,
        .fab {
          margin: 0 5px 0 0;
        }
        &:hover {
          .fas,
          .fab {
            color: $main-color;
          }
        }
        &:first-child {
          margin-left: 0;
        }

        a {
          font-weight: 500;
          font-size: 0.9rem;
          text-decoration: none;
          color: $text-color;
          border-color: #2c3e50;
          line-height: 1.4rem;
          display: inline-block;
          cursor: pointer;
        }
      }
      .logout-item {
        color: white;
        cursor: pointer;
      }
    }
  }

  &.offline {
    background: $navbar-offline-bg;
    .links .nav-links .nav-item a,
    .site-name {
      color: $text-color;
    }
  }

  .user-picture {
    max-height: 37px;
    width: 37px;
    margin-left: 10px;
    margin-right: -15px;
    border-radius: 50%;
  }

  .offline-label {
    padding: 0px 10px;
    border: 1px solid $text-color;
    border-radius: 5px;
    color: $text-color;
    margin-left: 1.5rem;
  }
}
</style>
