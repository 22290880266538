<template>
  <div class="page-wrapper">
    <h2>Projects</h2>
    <div class="homepage-content">
      <card
        :project="{
          link: 'https://blackpaper-b60f0.web.app/',
          color: '#e5002b',
          name: 'Firebase Market',
          desc: 'CMS for online shop developed with Angular 8 and Firebase',
          img: 'img/market.jpg'
        }"
      ></card>
      <card
        :project="{
          link:
            'https://play.google.com/store/apps/details?id=poker.alexandru.minzatanu',
          color: '#e5002b',
          name: 'Poker Club',
          desc:
            'True random poker slots game developed with Ionic Framework and cordova',
          img: 'img/poker-club.png'
        }"
      ></card>

      <card
        :project="{
          link: 'https://accountant-tools-10.web.app/reception-note',
          color: '#e5002b',
          name: 'Reception Notes',
          desc:
            'Angular 7 app that cand generate and calculate reception notes in printing format',
          img: 'img/r-note.jpg'
        }"
      ></card>

      <card
        :project="{
          link: 'https://alexminzatanu.com',
          color: '#e5002b',
          name: 'Portfolio Site',
          desc: 'This site PWA with VueJs and firebase hosting and cloud store',
          img: 'img/me.jpg'
        }"
      ></card>
    </div>
    <h2>Social</h2>
    <div class="homepage-content">
      <social></social>
    </div>
    <h2>Stackshare</h2>
    <p>(You have 30s to click on highlighted icons)</p>
    <h3>
      Time:
      <strong>{{ endTimestamp - startTimestamp }}</strong> ms / Score:
      <strong>{{ score }}</strong>
    </h3>
    <div class="homepage-content">
      <section class="max-width">
        <div
          v-for="tech in techs"
          :key="tech.id"
          class="tech"
          :class="{ 'tech--hl': tech.id === currentHighlightedTech }"
          :style="{ backgroundImage: 'url(' + tech.img + ')' }"
          @click="clickedHighlightedTech(tech.id)"
        ></div>
      </section>
    </div>
    <footer>
      <span>
        Alex Mînzățanu
        <br />alexandru.minzatanu@gmail.com <br />2020
      </span>
    </footer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Card from '@/components/Card'
import Social from '@/components/Social'

export default {
  components: { Card, Social },

  computed: {
    ...mapState('techgame', [
      'techs',
      'currentHighlightedTech',
      'score',
      'endTimestamp',
      'startTimestamp'
    ]),
    ...mapState('app', ['appTitle'])
  },
  methods: {
    ...mapMutations('techgame', ['clickedHighlightedTech'])
  },
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'Alex Mînzățanu Portfolio',
          content: `This site is a test sandbox for web techs`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@keyframes pulse {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.max-width {
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 440px;
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  h2 {
    margin-top: 15px;
    line-height: 130px;
    font-size: 50px;
    font-weight: bold;
  }
  footer {
    margin: 100px 10px 10px 10px;
    box-shadow: $shadow;
    background: linear-gradient(
      135deg,
      adjust-hue($footer-bg, -10deg),
      $footer-bg
    );
    border-radius: $border-radius;
    height: 300px;
    width: calc(100% - 20px);
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    span {
      flex: 1;
    }
  }
  .homepage-content {
    width: 100vw;
    padding: 0 5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    .tech {
      width: 100px;
      height: 100px;
      margin: 4px;
      filter: grayscale(100%);
      box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      background-size: cover;
      &--hl {
        filter: grayscale(0%);
        cursor: pointer;
      }
    }

    span.s1 {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 200px;
      font-weight: 800;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.2);
    }

    span.s2 {
      font-weight: 800;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 200px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.2);
    }
  }
}
</style>
