import firebase from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyBZAaVSAWoAWieuQMzIXDu_r6JthsVHuiM',
  authDomain: 'alexmin-300192.firebaseapp.com',
  databaseURL: 'https://alexmin-300192.firebaseio.com',
  projectId: 'alexmin-300192',
  storageBucket: '',
  messagingSenderId: '879468524027',
  appId: '1:879468524027:web:5c3f8bd653929713'
}

firebase.initializeApp(config)
