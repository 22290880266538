<template>
  <a class="card" :href="project.link" target="_blank">
    <div class="front" :style="{ backgroundImage: 'url(' + project.img + ')' }">
      <p>{{ project.name }}</p>
    </div>
    <div class="back">
      <div>
        <p>{{ project.desc }}</p>
        <button class="button">Show me</button>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    project: Object
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
*,
*:before,
*:after {
  box-sizing: border-box;
}

@mixin mobile($size: 640px) {
  @media screen and (max-width: $size) {
    @content;
  }
}
.card {
  color: inherit;
  cursor: pointer;
  width: calc(33% - 2rem);
  min-width: calc(33% - 2rem);
  height: 400px;
  min-height: 400px;
  perspective: 1000px;
  margin: 1rem;
  position: relative;
  @include mobile(800px) {
    width: calc(50% - 2rem);
  }
  @include mobile(500px) {
    width: 100%;
  }
}

.front,
.back {
  display: flex;
  border-radius: $border-radius;
  background-position: center;
  background-size: cover;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  // overflow: hidden;
}

.front {
  background-size: cover;
  background-blend-mode: overlay;
  padding: 2rem;
  font-size: 1.618rem;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      adjust-hue($composition-color, -20deg),
      $composition-color
    );
    opacity: 0.25;
    z-index: -1;
  }
  .card:hover & {
    transform: rotateY(180deg);
  }
  .card:nth-child(even):hover & {
    transform: rotateY(-180deg);
  }
}

.back {
  background: #fff;
  transform: rotateY(-180deg);
  padding: 0 2em;

  .button {
    background: linear-gradient(
      135deg,
      adjust-hue($composition-color, -20deg),
      $composition-color
    );

    &:before {
      box-shadow: 0 0 10px 10px rgba($composition-color, 0.25);
      background-color: rgba($composition-color, 0.25);
    }
  }

  .card:hover & {
    transform: rotateY(0deg);
  }

  .card:nth-child(even) & {
    transform: rotateY(180deg);

    .button {
      background: linear-gradient(
        135deg,
        adjust-hue($secondary-color, -20deg),
        $secondary-color
      );

      &:before {
        box-shadow: 0 0 10px 10px rgba($secondary-color, 0.25);
        background-color: rgba($secondary-color, 0.25);
      }
    }
  }

  .card:nth-child(even):hover & {
    transform: rotateY(0deg);
  }
}

.button {
  $height: 40px;
  transform: translateZ($height);
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: bold;
  color: #fff;
  padding: 0.5em 1em;
  border-radius: $border-radius;
  font: inherit;
  border: none;
  position: relative;
  transform-style: preserve-3d;
  transition: 300ms ease;

  &:before {
    transition: 300ms ease;
    position: absolute;
    display: block;
    content: '';
    transform: translateZ(-$height);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    border-radius: $border-radius;
    left: 10px;
    top: 16px;
  }

  &:hover {
    transform: translateZ($height + 15px);

    &:before {
      transform: translateZ(-($height + 15px));
    }
  }

  &:active {
    transform: translateZ($height/2);

    &:before {
      transform: translateZ(-($height/2));
      top: 12px;
    }
  }
}
</style>
