import state from './techgame.state'
import mutations from './techgame.mutations'
import actions from './techgame.actions'
import getters from './techgame.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
