<template>
  <div class="options">
    <div
      class="option"
      :class="{ active: isActive === 0 }"
      style="--optionBackground:url(https://images.unsplash.com/photo-1515879218367-8466d910aaa4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80);"
      @click="isActive = 0"
    >
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <i class="fab fa-github"></i>
        </div>
        <div class="info">
          <div class="main">Github</div>
          <div class="sub">alexandruminzatanu</div>
        </div>
      </div>
    </div>
    <div
      class="option"
      :class="{ active: isActive === 1 }"
      style="--optionBackground:url(https://images.unsplash.com/photo-1562564055-71e051d33c19?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);"
      @click="isActive = 1"
    >
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <i class="fab fa-linkedin-in"></i>
        </div>
        <div class="info">
          <div class="main">Linkedin</div>
          <div class="sub">alexandruminzatanu</div>
        </div>
      </div>
    </div>
    <div
      class="option"
      :class="{ active: isActive === 2 }"
      style="--optionBackground:url(https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80);"
      @click="isActive = 2"
    >
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <i class="fab fa-twitter"></i>
        </div>
        <div class="info">
          <div class="main">Twitter</div>
          <div class="sub">@MinzatanuAlex</div>
        </div>
      </div>
    </div>
    <div
      class="option"
      :class="{ active: isActive === 3 }"
      style="--optionBackground:url(https://images.unsplash.com/photo-1516321497487-e288fb19713f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80);"
      @click="isActive = 3"
    >
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <i class="fab fa-facebook-f"></i>
        </div>
        <div class="info">
          <div class="main">Facebook</div>
          <div class="sub">AlexandruMinzatanu</div>
        </div>
      </div>
    </div>
    <div
      class="option"
      :class="{ active: isActive === 4 }"
      style="--optionBackground:url(https://images.unsplash.com/photo-1466096115517-bceecbfb6fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);"
      @click="isActive = 4"
    >
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <i class="fas fa-envelope"></i>
        </div>
        <div class="info">
          <div class="main">Mail</div>
          <div class="sub">alexandru.minzatanu@gmail.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
$optionDefaultColours: #ed5565, #fc6e51, #ffce54, #2ecc71, #5d9cec, #ac92ec;
.options {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;

  min-width: 600px;
  max-width: 900px;
  width: calc(100% - 100px);

  height: 400px;

  @for $i from 1 through 4 {
    @media screen and (max-width: 798px - $i*80) {
      min-width: 600px - $i * 80;
      .option:nth-child(#{6-$i}) {
        display: none;
      }
    }
  }

  .option {
    position: relative;
    overflow: hidden;
    min-width: 60px;
    margin: 10px;
    background: var(--optionBackground, var(--defaultBackground, #e6e9ed));
    background-size: auto 120%;
    background-position: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    @for $i from 1 through length($optionDefaultColours) {
      &:nth-child(#{$i}) {
        --defaultBackground: #{nth($optionDefaultColours, $i)};
      }
    }
    &.active {
      flex-grow: 10000;
      transform: scale(1);
      max-width: 600px;
      margin: 0px;
      border-radius: 40px;
      background-size: auto 100%;
      .shadow {
        box-shadow: inset 0 -120px 120px -120px black,
          inset 0 -120px 120px -100px black;
      }
      .label {
        bottom: 20px;
        left: 20px;
        .info > div {
          left: 0px;
          opacity: 1;
        }
      }
    }
    &:not(.active) {
      flex-grow: 1;

      border-radius: 30px;
      .shadow {
        bottom: -40px;
        box-shadow: inset 0 -120px 0px -120px black,
          inset 0 -120px 0px -100px black;
      }
      .label {
        bottom: 10px;
        left: 10px;
        .info > div {
          left: 20px;
          opacity: 0;
        }
      }
    }
    .shadow {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;

      height: 120px;

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    }
    .label {
      display: flex;
      position: absolute;
      right: 0px;

      height: 40px;
      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      .icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        min-width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 100%;

        background-color: white;
        color: var(--defaultBackground);
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-left: 10px;

        color: white;

        white-space: pre;
        > div {
          position: relative;

          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95),
            opacity 0.5s ease-out;
        }
        .main {
          font-weight: bold;
          font-size: 1.2rem;
        }
        .sub {
          transition-delay: 0.1s;
        }
      }
    }
  }
}
</style>
